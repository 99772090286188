import { DynamicDistributionChart } from 'app/shared/charts/distribution';
import React, { useEffect, useState } from 'react';

export const DistributionChartInstance = ({
  type = '',
  field = '',
  logs = [],
  contacts = [],
  is_loading = true,
  onDelete = () => {}
}) => {
  const field_name = field.includes('.') ? field.split('.')[1] : field;
  const [data, setData] = useState([]);

  const processAge = (date_of_birth) => {
    if (!date_of_birth || !new Date(date_of_birth)) return 'Unspecified';
    const age = new Date().getFullYear() - new Date(date_of_birth).getFullYear();
    if (age < 18) return 'Under 18';
    if (age >= 18 && age < 31) return '18 - 30';
    if (age >= 31 && age < 46) return '31 - 45';
    if (age >= 46 && age < 66) return '46 - 65';
    if (age > 65) return 'Over 65';
  };

  const prepareData = (logs = [], contacts = []) => {
    const contact_by_email = contacts.reduce((sac, contact) => {
      if (!contact) return sac;

      let property_name = field;
      if (field === 'age') property_name = 'date_of_birth';
      if (field === 'domain') property_name = 'email';

      const property_value = contact[property_name] || (contact.extra_data || {})[property_name];
      return { ...sac, [contact.email]: property_value };
    }, {});

    const field_value_counter = {};
    for (let i = 0; i < logs.length; i++) {
      const { contact_email } = logs[i];

      let value = contact_by_email[contact_email];
      if (field === 'domain') {
        const [, domain] = contact_email.split('@');
        value = domain;
      } else if (field === 'age') {
        const date_of_birth = contact_by_email[contact_email];
        value = processAge(date_of_birth);
      }

      if (!value) continue;

      if (field_value_counter[value]) field_value_counter[value] += 1;
      else field_value_counter[value] = 1;
    }

    const prepared_data = [];
    for (const key of Object.keys(field_value_counter)) {
      prepared_data.push({ name: key, value: field_value_counter[key] });
    }

    const sorted_data = prepared_data.sort((a, b) => b.value - a.value);
    const top_five = sorted_data.splice(0, 5);
    const sum_of_rest = sorted_data.reduce((sac, datum) => sac + datum.value, 0);
    if (sum_of_rest) top_five.push({ name: 'others', value: sum_of_rest });

    return top_five;
  };

  useEffect(() => {
    const prepared_data = prepareData(logs, contacts);
    setData(prepared_data);
  }, [logs, contacts]);

  return (
    <>
      <DynamicDistributionChart
        title={`${type} by ${field_name}`}
        is_loading={is_loading}
        data={data}
        onDelete={() => onDelete(field)}
      />
    </>
  );
};
