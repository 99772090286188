import { GridColumn, GridRow } from 'app/layouts/grid';
import { PageLoader } from 'app/shared/loader';
import { useSubscriptionService } from 'hooks/sales/subscription';
import useScreenSize from 'hooks/size';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { ActiveSubscriptionItem } from './item';

export const ActiveSubcriptions = () => {
  const dispatch = useDispatch();
  const { isLargeDesktop, isTablet } = useScreenSize();
  const { fetchSubscription } = useSubscriptionService();

  const [is_loading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Subscriptions', path: '.' }]));

    setIsLoading(true);
    fetchSubscription({ query_string: 'status=active' }).then(
      ({ subscriptions: active_subscriptions }) => {
        setSubscriptions(active_subscriptions);
        setIsLoading(false);
      }
    );
  }, [setIsLoading]);

  return is_loading ? (
    <PageLoader />
  ) : (
    <GridRow num_of_columns={isTablet ? 2 : isLargeDesktop ? 4 : 3}>
      {subscriptions.map((subscription) => (
        <GridColumn key={subscription.plan_code}>
          <ActiveSubscriptionItem data={subscription} />
        </GridColumn>
      ))}
    </GridRow>
  );
};
