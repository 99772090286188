import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { useDebouce } from 'hooks/debounce';

import { MainForm } from './main';
import { SidebarForm } from './sidebar';
import { EditorLayout } from 'app/member/editors/layout';

import { delay, isHTMLBodyEmpty } from 'app/shared/utils/general';
import { campaignValidationConfig } from 'app/shared/utils/form/validation/campaign';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import useValidator from 'hooks/validator';
import { useCampaignService } from 'hooks/mailing/campaign';
import { useAudienceService } from 'hooks/users/audience';
import { useMailboxService } from 'hooks/users/mailbox';
import { useDraftService } from 'hooks/mailing/draft';
import { useEventsService } from 'hooks/automation/event';

export const CreateCampaign = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { clearTimer, debounce } = useDebouce();
  const { fetchAudiences } = useAudienceService();
  const { createCampaign, fetchCampaign, prepareTestCampaignData } = useCampaignService();
  const { createDraft, updateDraft } = useDraftService();
  const { fetchEvents } = useEventsService();
  const { fetchMailboxes } = useMailboxService();

  const [campaign_id, setCampaignId] = useState(id);
  const [audiences, setAudiences] = useState([]);
  const [campaign, setCampaign] = useState({});
  const [events, setEvents] = useState([]);
  const [mailbox, setMailbox] = useState({ emails: [] });
  const [loading, setLoading] = useState(false);
  const [draft_status, setDraftStatus] = useState('');
  const [send_to_test_audience, setSendToTestAudience] = useState(false);
  const [send_campaign, setSendCampaign] = useState(false);
  const [test_audience, setTestAudience] = useState({});

  const { errors, validateField, form_is_valid } = useValidator(campaignValidationConfig);

  useEffect(() => {
    if (id) {
      fetchCampaign(campaign_id).then(({ campaign }) => {
        Object.keys(campaign).forEach(function (key) {
          validateField(key, campaign[key]);
        });
        setCampaign(campaign);
      });
    }

    fetchAudiences({
      query_string: 'is_archived=0&return_only=name,id,is_test_audience'
    }).then(({ audiences }) => {
      setAudiences(audiences);
      setTestAudience(() => audiences.find((audience) => audience.is_test_audience));
    });

    fetchMailboxes().then(({ mailboxes }) => {
      setMailbox(mailboxes[0] || { emails: [] });
    });

    fetchEvents({ query_string: 'type=user' }).then(({ events }) => {
      setEvents(events.map((event) => ({ ...event, label: event.name, value: event.code })));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleAttributeChange = (attr_key, attr_value) => {
    if (attr_key === 'mailing_lists' && test_audience && attr_value[0] === test_audience.id) {
      validateField('schedule', campaign.schedule || {}, { is_test_campaign: true });
    } else if (attr_key === 'mailing_lists') {
      validateField('schedule', campaign.schedule || {}, { is_test_campaign: false });
    }

    validateField(attr_key, attr_value);
    const new_value = { ...campaign, [attr_key]: attr_value };
    setCampaign(() => new_value);
    debounce(() => saveDraft(new_value));
  };

  const saveDraft = async (campaign) => {
    if (draft_status) return;
    const data = { ...campaign };

    setDraftStatus('Saving draft');
    const response = campaign_id
      ? await updateDraft(campaign_id, { data })
      : await createDraft({ data });

    if (!response) {
      setDraftStatus('Unable to save draft');
    } else if (!campaign_id) {
      setCampaignId(() => response.id);
      setDraftStatus('Draft saved.');
    }

    await delay(1);
    setDraftStatus('');
  };

  const handleSave = async () => {
    clearTimer();

    if (loading) return;

    if (!mailbox.emails.includes(campaign.sender_email)) {
      toast.error('Sender Email not verified.');
      return;
    }

    if (!campaign.body || isHTMLBodyEmpty(campaign.body)) {
      toast.error('Campaign body cannot be empty.');
      return;
    }

    if (is_test_audience_selected) {
      setSendToTestAudience(true);
      return;
    }

    setSendCampaign(true);
  };

  const handleSendCampaignToTestAudience = async (proceed) => {
    setSendToTestAudience(false);
    if (!proceed) return;

    setLoading(true);
    const { data } = prepareTestCampaignData(campaign);
    const response = await createCampaign({ data });

    if (!response) {
      toast.error('Unable to send test campaign. Please, try again');
      setLoading(false);
      return;
    }

    setLoading(false);
    toast.success(`Campaign queued successfully.`);
    await delay(0.5);
    navigate('/campaigns');
  };

  const handleSendCampaign = async (proceed) => {
    setSendCampaign(false);
    if (!proceed) return;

    setLoading(true);
    const data = { ...campaign, status: 'queued' };
    const response = campaign_id
      ? await updateDraft(campaign_id, {
          data
        })
      : await createCampaign({ data });

    if (!response) {
      toast.error(`Unable to enqueue campaign at this moment. Please try again.`);
      setLoading(false);
      return;
    }

    setLoading(false);
    toast.success(`Campaign enqueued successfully.`);
    await delay(0.5);
    navigate('/campaigns');
  };

  const is_test_audience_selected =
    campaign.mailing_lists?.length > 0 && campaign.mailing_lists[0] === test_audience?.id;

  const Main = <MainForm onEditorChange={handleAttributeChange} value={campaign.body} />;

  const Sidebar = (
    <SidebarForm
      audiences={audiences}
      events={events}
      testAudience={test_audience}
      isTestAudienceSelected={is_test_audience_selected}
      campaign={campaign}
      onChange={handleAttributeChange}
      mailbox={mailbox}
      setMailbox={setMailbox}
      draft_status={draft_status}
      errors={errors}
    />
  );

  return (
    <>
      <EditorLayout
        button_text={is_test_audience_selected ? 'Send Test Campaign' : 'Send Campaign'}
        main={Main}
        sidebar={Sidebar}
        onSave={handleSave}
        disable={!!draft_status || loading || !form_is_valid}
      />

      <ConfirmationDialog
        title="Send campaign to test audience?"
        message="You are about to send this campaign to only your test audience. Note that it won't be sent to other contacts not within this audience list"
        callback={handleSendCampaignToTestAudience}
        is_open={send_to_test_audience}
      />

      <ConfirmationDialog
        title="Send Campaign"
        message="Are you sure you want to send this campaign?"
        callback={handleSendCampaign}
        is_open={send_campaign}
      />
    </>
  );
};
