const flattenObject = (object = {}, result = {}, concatenate_keys = false, root_key = '') => {
  let flattened_object = {};

  const entries = Object.entries(object);

  for (const [key, value] of entries) {
    const item_key = root_key && concatenate_keys ? `${root_key}.${key}` : key;
    if (value && typeof value === 'object') {
      flattened_object = {
        ...flattenObject(value, { ...flattened_object }, concatenate_keys, item_key)
      };
    } else {
      flattened_object[item_key] = value;
    }
  }

  return { ...result, ...flattened_object };
};

const scrambleSensitiveData = (SENSITIVE_DATA_KEYS = [], object = {}) => {
  const scrambled_data = {};
  for (const [key, value] of Object.entries(object)) {
    scrambled_data[key] = value;
    for (const piid_key of SENSITIVE_DATA_KEYS) {
      if (key.toLowerCase().includes(piid_key.toLowerCase())) {
        scrambled_data[key] = value.toString().replace(/\w/gi, '*');
      }
    }
  }

  return scrambled_data;
};

module.exports = { flattenObject, scrambleSensitiveData };
