import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureFlag } from 'hooks/go-flags';
import landing_data from './data/landing.json';

/** */
import { setPageTitle } from 'store/actions/header';
import {
  addManyAudiencesToStore,
  addAudienceSearchResultsToStore,
  loadAudienceToStore,
  removeOneAudienceFromStore,
  removeManyAudiencesFromStore
} from 'store/actions/audience';

/** */
import { Button } from 'app/shared/button';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDatatable from 'app/shared/datatable/web/datatable';
import GmModal from 'app/shared/modal/modal';
import ListCreationForm from 'app/member/modules/mailing-lists/create/create';
import CloneMailingItem from '../clone/clone';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { toReadableNumber } from 'app/shared/utils/number';
import { truncateText } from 'app/shared/utils/general';
import { PermissionsContext } from 'contexts/permissions';
import { useContactService } from 'hooks/users/contact';
import { useAudienceService } from 'hooks/users/audience';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { useUserService } from 'hooks/iam/user';
import { LandingCard } from 'app/shared/onboarding/landing';
import { Spacer } from 'app/layouts/generic';

/** */
const DEFAULT_FILTERS = {
  is_archived: 0
};

const ListMailingLists = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { isFeatureAllowed } = useFeatureFlag();
  const { tenant_id } = useSelector((state) => state.user_data);
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const audiences_in_store = useSelector((state) => state.audiences);
  const { permissions } = useContext(PermissionsContext);

  const {
    deleteAudienceById,
    deleteAudiences,
    fetchAudiences,
    searchAudiences,
    updateAudience,
    updateAudiences
  } = useAudienceService();
  const { fetchAudienceContacts } = useContactService();
  const { fetchUserData } = useUserService();

  const [loading, setLoading] = useState(false);
  const [total_audiences, setTotalAudiences] = useState(0);
  const [audience_to_modify, setAudienceToModify] = useState({});
  const [audiences_to_modify, setAudiencesToModify] = useState([]);
  const [mailing_lists, setMailingLists] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [onboarding_config, setOnboardingConfig] = useState({
    hidden: true,
    has_created_audience: false
  });

  const [show_create_audience_modal, setShowCreateAudienceModal] = useState(false);
  const [show_clone_audience_modal, setShowCloneAudienceModal] = useState(false);
  const [show_archival_options, setShowArchivalOptions] = useState(false);
  const [show_bulk_deletion_confirmation, setShowBulkDeletionConfirmation] = useState(false);
  const [show_clone_audience_options, setShowCloneAudienceOptions] = useState(false);
  const [show_deletion_confirmation, setShowDeletionConfirmation] = useState(false);
  const [selected_item, setSelectedItem] = useState({});
  const [show_archived, setShowArchived] = useState(false);
  const [selected_filters, setSelectedFilters] = useState(DEFAULT_FILTERS);
  const [test_audience_id, setTestAudienceId] = useState();

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Audiences', path: '.' }]));

    isFeatureAllowed('Feature.AudienceArchival', { tenant_id }).then(({ is_permitted }) => {
      setShowArchivalOptions(is_permitted);
    });
    isFeatureAllowed('Feature.AudienceCloning', { tenant_id }).then(({ is_permitted }) => {
      setShowCloneAudienceOptions(is_permitted);
    });

    fetchUserData().then(({ user }) => {
      const { onboarding } = user;
      setOnboardingConfig((conf) => ({
        ...conf,
        hidden: !!(onboarding?.audience && onboarding?.audience.hidden)
      }));
    });
  }, []);

  useEffect(() => {
    const lists = Object.values(audiences_in_store);
    const selected_list_id = lists.find((item) => item.is_test_audience)?.id;
    setTestAudienceId(selected_list_id);
    setMailingLists(lists);
  }, [audiences_in_store]);

  useEffect(() => {
    handleDataRequest(0, 50);
  }, [selected_filters]);

  const build_query_string = (page = 0, population = 50) => {
    const query_str = Object.keys(selected_filters).reduce((str, key) => {
      return `${str}&${key}=${selected_filters[key]}`;
    }, '');
    return `return_only=name,id,description,created_on,is_archived,is_test_audience,time_stamp&page=${page}&population=${population}&sort_by=-created_on${query_str}`;
  };

  const config = {
    actions: {
      single: (audience) => [
        { label: 'View', value: 'view' },
        { label: 'Clone', value: 'clone', hidden: !show_clone_audience_options },
        { label: 'Delete', value: 'delete' },
        {
          label: audience.is_archived ? 'Unarchive' : 'Archive',
          value: audience.is_archived ? 'unarchive' : 'archive',
          hidden: !show_archival_options
        }
      ],
      bulk: [
        {
          label: show_archived ? 'Unarchive' : 'Archive',
          value: show_archived ? 'unarchive' : 'archive'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        isTitle: true
      },
      {
        title: 'Description',
        key: 'description',
        formatter: (value) => truncateText(value, 35) || 'N/A',
        isTagline: true
      },
      {
        title: 'No. of Contacts',
        key: 'contacts',
        formatter: (value) => toReadableNumber(value) || '0'
      },
      {
        title: 'Date created',
        key: 'created_on',
        formatter: (value) => (value ? new Date(value).toDateString() : ''),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: mailing_lists.sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history(`/audiences/${id}/query`);
  };

  const toggleIsArchived = async () => {
    setShowArchived(!show_archived);
    setSelectedFilters((filters) => ({
      ...filters,
      is_archived: filters.is_archived ? 0 : 1
    }));
  };

  const handleBulkArchivalToggle = async (action, data = {}) => {
    const ids_to_update = data.map((audience) => audience.id);
    setLoading(true);
    const result = await updateAudiences({
      data: {
        options: { id: ids_to_update.join(',') },
        data: {
          is_archived: action === 'archive'
        }
      }
    });
    setLoading(false);

    if (!result) return;
    handleDataRequest();
    toast.success(
      `Selected Audiences have been ${action === 'archive' ? 'archived' : 'unarchived'}`
    );
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const query_string = build_query_string(page, population);
      const { audiences, size } = await fetchAudiences({ query_string });
      setOnboardingConfig((conf) => ({ ...conf, has_created_audience: !!size }));

      const aud_ids = [];
      Promise.all(
        audiences.map((audience) => {
          aud_ids.push(audience.id);
          return fetchAudienceContacts(audience.id, { query_string: `count=1` });
        })
      ).then((responses) => {
        const count_by_id = responses.reduce((s, { size }, index) => {
          return {
            ...s,
            [aud_ids[index]]: size
          };
        }, {});

        const parsed_audiences = audiences.map((a) => ({ ...a, contacts: count_by_id[a.id] }));
        if (!page || page === 0) {
          dispatch(loadAudienceToStore(parsed_audiences));
        } else {
          dispatch(addManyAudiencesToStore(parsed_audiences));
        }
      });
      setTotalAudiences(size);
    } catch (e) {
      dispatch(addManyAudiencesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleDatatableAction = async (payload) => {
    const { name, type, data } = payload;
    if (type === 'single') {
      switch (name) {
        case 'view':
          history(`/audiences/${data.id}/query`);
          break;
        case 'clone':
          setShowCloneAudienceModal(true);
          setSelectedItem(data);
          break;
        case 'archive':
        case 'unarchive':
          handleSingleArchivalToggle(name, data);
          break;
        case 'delete':
          setAudienceToModify(data);
          setShowDeletionConfirmation(true);
          break;
        default:
      }
    } else if (type === 'bulk') {
      switch (name) {
        case 'archive':
        case 'unarchive':
          handleBulkArchivalToggle(name, data);
          break;
        case 'delete':
          setAudiencesToModify(data);
          setShowBulkDeletionConfirmation(true);
          break;
        default:
      }
    }
  };

  const handleBulkDeletion = async (is_permitted) => {
    if (is_permitted) {
      const audience_ids = audiences_to_modify.map((audience) => audience.id);
      const data = { options: { id: audience_ids.join(',') } };
      const result = await deleteAudiences({ data });

      if (result) {
        dispatch(removeManyAudiencesFromStore(audience_ids));
      }
    }

    setAudiencesToModify([]);
    setShowBulkDeletionConfirmation(false);
  };

  const handleDeletion = async (is_permitted) => {
    if (is_permitted) {
      toast.info('Deleting audience.');
      const result = await deleteAudienceById(audience_to_modify?.id);
      if (result) {
        dispatch(removeOneAudienceFromStore(audience_to_modify.id));
      }
    }

    setAudienceToModify({});
    setShowDeletionConfirmation(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { audiences, size } = await searchAudiences(keys, keyword, {
        query_string: build_query_string(page, population)
      });

      const aud_ids = [];
      Promise.all(
        audiences.map((audience) => {
          aud_ids.push(audience.id);
          return fetchAudienceContacts(audience.id, { query_string: `count=1` });
        })
      ).then((responses) => {
        const count_by_id = responses.reduce((s, { size }, index) => {
          return {
            ...s,
            [aud_ids[index]]: size
          };
        }, {});

        const parsed_audiences = audiences.map((a) => ({ ...a, contacts: count_by_id[a.id] }));
        if (page === 0) return dispatch(addAudienceSearchResultsToStore(parsed_audiences));
        dispatch(addManyAudiencesToStore(parsed_audiences));
      });
      setTotalAudiences(size);
    } catch (e) {
      dispatch(addManyAudiencesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSingleArchivalToggle = async (action = 'archive', data = {}) => {
    const data_to_update = {
      is_archived: action === 'archive'
    };
    setLoading(true);
    const result = await updateAudience(data.id, {
      data: data_to_update
    });
    setLoading(false);

    if (!result) return;
    handleDataRequest();
    toast.success(`Audience ${action === 'archive' ? 'archived' : 'unarchived'}`);
  };

  const table_actions = (
    <>
      {show_archival_options && (
        <Button
          icon_name="show"
          text={show_archived ? 'View Unarchived' : 'View Archived'}
          type="secondary"
          onClick={() => toggleIsArchived()}
          disabled={!permissions['audience:view']}
        />
      )}
      <Button
        text="Audience"
        icon_name="add"
        onClick={() => setShowCreateAudienceModal(true)}
        disabled={!permissions['audience:create']}
      />
    </>
  );

  return (
    <PageContentWrapper>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
          showHeader
        />
      ) : (
        <div>
          {!onboarding_config.hidden && (
            <>
              <LandingCard
                data={landing_data}
                control_config={onboarding_config}
                onHide={setOnboardingConfig}
              />
              <Spacer multiple={4} />
            </>
          )}
          <WebDatatable
            action={handleDatatableAction}
            checkbox
            config={{
              ...config,
              total_count: total_audiences
            }}
            onClick={handleItemClick}
            loading_data={loading}
            table_actions={table_actions}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
          {show_create_audience_modal && (
            <GmModal
              title="Create Audience"
              show_title={true}
              show_modal={show_create_audience_modal}
              onClose={() => setShowCreateAudienceModal(false)}
            >
              <ListCreationForm current_test_audience_id={test_audience_id} />
            </GmModal>
          )}
          <GmModal
            title="Clone Audience"
            show_title={true}
            show_modal={show_clone_audience_modal}
            onClose={() => setShowCloneAudienceModal(false)}
          >
            <CloneMailingItem line_item={selected_item} />
          </GmModal>
          <ConfirmationDialog
            message={'This action cannot be undone.'}
            title="Delete audience?"
            is_open={show_deletion_confirmation}
            callback={handleDeletion}
          />
          <ConfirmationDialog
            message={'This action cannot be undone.'}
            title="Delete audiences?"
            is_open={show_bulk_deletion_confirmation}
            callback={handleBulkDeletion}
          />
        </div>
      )}
    </PageContentWrapper>
  );
};

export default ListMailingLists;
