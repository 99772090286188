import landing_data from './data/landing.json';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import { WebPushListPage } from './list';
import { useWebPushService } from 'hooks/messaging/push/web';
import { useUserService } from 'hooks/iam/user';
import { LandingCard } from 'app/shared/onboarding/landing';
import { Spacer } from 'app/layouts/generic';

export const WebPushModule = () => {
  const dispatch = useDispatch();
  const { fetchUserData } = useUserService();
  const { fetchPushMessages } = useWebPushService();

  const [onboarding_config, setOnboardingConfig] = useState({
    hidden: true,
    has_created_message: false
  });

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Messaging', path: '/messaging/sms' },
        { title: 'Web Push Messaging', path: '.' }
      ])
    );

    Promise.all([fetchUserData(), fetchPushMessages()]).then(([{ user }, { size }]) => {
      const { onboarding } = user;
      setOnboardingConfig((conf) => ({
        ...conf,
        hidden: !!(onboarding?.web_push && onboarding?.web_push.hidden),
        has_created_message: !!size
      }));
    });
  }, []);

  return (
    <>
      {!onboarding_config.hidden && (
        <>
          <LandingCard
            data={landing_data}
            control_config={onboarding_config}
            onHide={setOnboardingConfig}
          />
          <Spacer multiple={4} />
        </>
      )}
      <Routes>
        <Route path="/*" element={<WebPushListPage />} />
      </Routes>
    </>
  );
};
