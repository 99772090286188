import GmModal from 'app/shared/modal/modal';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { Accordion } from 'rsuite';
import styles from './view.module.css';

const HistoryItemDetails = ({ data }) => {
  return (
    <div className={styles.detailsWrapper}>
      <Accordion defaultActiveKey={1}>
        {data.occurrences.map((occurrence, index) => (
          <Accordion.Panel
            key={occurrence.occurred_at}
            eventKey={index + 1}
            header={`Occurred at ${new Date(occurrence.occurred_at).toDateString()} ${new Date(
              occurrence.occurred_at
            ).toTimeString()}`}
          >
            <ReactJson
              theme="monokai"
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              src={occurrence.context}
            />
          </Accordion.Panel>
        ))}
      </Accordion>
    </div>
  );
};

export const HistoryViewPage = ({ data }) => {
  const [show_details_modal, setShowDetailsModal] = useState(false);

  const handleViewDetails = () => {
    setShowDetailsModal(true);
  };

  return (
    <>
      <div className={styles.wrapper} onClick={handleViewDetails}>
        {data.contact_email}
      </div>
      <GmModal
        title={`Invocation details: ${data.contact_email}`}
        show_title
        show_modal={show_details_modal}
        onClose={() => setShowDetailsModal(false)}
      >
        <HistoryItemDetails data={data} />
      </GmModal>
    </>
  );
};
