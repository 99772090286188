export const convertToPercent = (value, total) => {
  if (!value || !total) return 0;
  return (Number(value) / Number(total)) * 100;
};

export const generateRandomNumber = () => {
  return Math.random() * Date.now();
};

export const toReadableNumber = (value) => {
  if (!value) return 0;
  if (isNaN(value)) return value;
  return Number(Number(value).toFixed(2)).toLocaleString();
};
