import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ConfigProvider } from '@arco-design/web-react';
import enUS from '@arco-design/web-react/es/locale/en-US';
import app_store from './store/_store';
import App from './app/app';
import * as serviceWorker from './serviceWorker';

import '@arco-themes/react-go-mailer/css/arco.css';
import './index.css';
import 'assets/css/color.css';
import 'assets/css/font.css';

const REACT_APP_ENV = process.env.REACT_APP_ENV;
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ConfigProvider locale={enUS}>
        <Provider store={app_store}>
          <Helmet>
            {REACT_APP_ENV !== 'production' && <meta name="robots" content="noindex" />}
            {REACT_APP_ENV === 'production' && (
              <>
                <title>GoMailer: Focused & Simple Marketing</title>
                <meta
                  name="description"
                  content="Go-Mailer: Simple. Focused. Intuitive Email marketing platform built to help you grow and maintain a sustainable and enduring brand."
                />
                <meta
                  name="keywords"
                  content="Go-Mailer, Simple, Focused, Intuitive, Email Marketing, Pay as you go, sustainable brand, enduring brand, Email, Marketing, Digital Marketing"
                />
              </>
            )}
          </Helmet>
          <App />
        </Provider>
      </ConfigProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
