import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { TreeContext } from 'contexts/automation';
import { useResourceQuery } from 'hooks/automation/resources';
import { useAutomationStepService } from 'hooks/automation/steps';
import React, { useContext, useEffect, useState } from 'react';
import styles from './config.module.css';
import { useEffectsService } from 'hooks/automation/effects';
import { toast } from 'react-toastify';
import { SimpleInput } from 'app/shared/input';
import { EFFECT_ADD_CONTACT_TAG } from 'app/shared/constants/automation';

export const AutomationActionConfig = () => {
  const { step_modal, toggleStepModal } = useContext(TreeContext);

  const { fetchEffects } = useEffectsService();
  const { fetchResources } = useResourceQuery();
  const { createStep, updateStep } = useAutomationStepService();

  const [effects, setEffects] = useState([]);
  const [creating, setCreating] = useState(false);
  const [resources, setResources] = useState([]);
  const [selected_action, setSelectedAction] = useState(null);
  const [selected_resource, setSelectedResource] = useState(null);
  const [tags, setTags] = useState('');

  //
  const is_edit_mode = step_modal.mode === 'edit';

  useEffect(() => {
    fetchEffects().then(({ effects: raw_effects, error }) => {
      if (error) return toast.error(error);

      const parsed_effects = raw_effects.map((effect) => ({
        ...effect,
        label: effect.name,
        value: effect.code
      }));

      setEffects(() => parsed_effects);
    });
  }, []);

  useEffect(() => {
    if (!effects.length || !is_edit_mode) return;
    const { data } = step_modal;
    const { effect: chosen_effect } = data;

    if (!chosen_effect) return;
    setSelectedAction(() => effects.find((effect) => effect.code === chosen_effect) || {});
  }, [step_modal.mode, effects]);

  useEffect(() => {
    if (!selected_action || !Object.keys(selected_action).length) return;
    if (selected_action.value === EFFECT_ADD_CONTACT_TAG) {
      if (is_edit_mode) {
        const { data } = step_modal;
        const [, tag] = (data.target || '').split(':');
        setTags(() => tag);
      }
      return;
    }

    fetchResources(selected_action.resource_class, '').then(
      ({ error, resources: raw_resources }) => {
        if (error) return;

        const parsed_resources = raw_resources.map((res) => ({
          ...res,
          label: res.name || res.title || res.link,
          value: res.id
        }));

        setResources(() => parsed_resources);

        if (is_edit_mode && selected_action.value === step_modal.data.effect) {
          const { data } = step_modal;
          const [, resource_id] = data.target.split(':');
          if (isNaN(resource_id)) return;
          setSelectedResource(
            () =>
              parsed_resources.find((resource) => Number(resource.value) === Number(resource_id)) ||
              {}
          );
        }
      }
    );
  }, [selected_action]);

  const handleActionSelection = (selection = null) => {
    if (!selection) return;
    setSelectedAction(selection);
  };

  const handleResourceSelection = (selection = null) => {
    if (!selection) return;
    setSelectedResource(selection);
  };

  const submit = async () => {
    let target = `${selected_action.resource_class}:${selected_resource?.value}`;
    if (selected_action?.value === EFFECT_ADD_CONTACT_TAG) {
      target = `${selected_action.resource_class}:${tags}`;
    }

    const data = {
      branch_value: is_edit_mode ? step_modal.data.branch_value : step_modal.branch_value,
      children: [],
      effect: selected_action.value,
      parents: step_modal.parents,
      source: is_edit_mode ? step_modal.data.source : step_modal.source,
      target,
      template_id: step_modal.template_id,
      type: 'action',
      rules: is_edit_mode ? step_modal.data.rules : step_modal.rules
    };

    try {
      const step_id = is_edit_mode ? step_modal.data.id : null;
      setCreating(() => true);
      const result = step_id ? await updateStep(step_id, data) : await createStep(data);
      if (result) {
        setSelectedResource({});
        setSelectedAction({});
        toggleStepModal({ show: false });
      }
    } finally {
      setCreating(() => false);
    }
  };

  return (
    <div className={styles.formWrapper}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Action">
            <MultiSelect
              options={effects}
              onChange={handleActionSelection}
              value={selected_action}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          {selected_action?.value === EFFECT_ADD_CONTACT_TAG ? (
            <DetailItem title="Tags">
              <SimpleInput value={tags} onInput={setTags} />
            </DetailItem>
          ) : (
            <DetailItem title="Resource">
              <MultiSelect
                options={resources}
                onChange={handleResourceSelection}
                value={selected_resource}
              />
            </DetailItem>
          )}
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        <GridColumn />
        <GridColumn />
        <GridColumn>
          <Button text="Save" disabled={creating} onClick={submit} />
        </GridColumn>
      </GridRow>
    </div>
  );
};
