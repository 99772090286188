import { useSelector } from 'react-redux';
import * as EventReportService from 'services/automation/reports/event';

export const useEventReportService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchEventReports = async (options = {}) => {
    const { error, payload } = await EventReportService.read({ ...options, token });
    if (error) {
      return { reports: [], size: 0, error };
    }

    const {
      data: reports,
      meta: { size }
    } = payload;
    return { reports, size, error: null };
  };

  const searchEventReports = async (keys, keyword, options = {}) => {
    const { error, payload } = await EventReportService.search(keys, keyword, {
      token,
      ...options
    });
    if (error) {
      return { reports: [], size: 0 };
    }

    const {
      data: reports,
      meta: { size }
    } = payload;
    return { reports, size };
  };

  return {
    fetchEventReports,
    searchEventReports
  };
};
