import cx from 'classnames';
import React from 'react';
import styles from './info-section.module.css';

export const DetailItem = ({ className, title = '', required = false, children }) => {
  let kids = children;
  if (typeof children === 'string')
    kids = (
      <span style={{ wordBreak: 'break-all', display: 'inline-block', width: '100%' }}>
        {children}
      </span>
    );
  return (
    <div className={cx(styles.item, className)}>
      <p>
        {title} {required && <span className={styles.required}>*</span>}
      </p>
      {kids}
    </div>
  );
};
