/** */
const autoCompleter = (editor, placeholders) => {
  const onAction = (autocompleteApi, rng, value) => {
    editor.selection.setRng(rng);
    editor.insertContent(value);
    autocompleteApi.hide();
  };

  editor.ui.registry.addAutocompleter('templatestringcharacter', {
    ch: '@',
    fetch: (pattern) => {
      return new Promise(function (resolve) {
        const filtered_results = placeholders.filter((placeholder) =>
          placeholder.value.includes(pattern.substr(1))
        );
        const results = filtered_results.map((result) => ({
          ...result,
          type: 'autocompleteitem'
        }));
        resolve(results);
      });
    },
    minChars: 1,
    onAction
  });
};

export const tiny_config = (
  file_picker_callback = () => {},
  images_upload_handler = () => {},
  placeholder = '',
  auto_complete_options = []
) => ({
  autoresize_bottom_margin: 25,
  autoresize_overflow_padding: 25,
  browser_spellcheck: true,
  default_link_target: '_blank',
  file_picker_callback: () => file_picker_callback,
  file_picker_types: 'file',
  fullpage_default_doctype: '<!DOCTYPE html>',
  fullpage_default_encoding: 'UTF-8',
  height: '100vh',
  images_reuse_filename: true,
  images_upload_handler,
  image_uploadtab: true,
  menubar: false,
  placeholder,
  plugins:
    'autolink autosave code fullpage fullscreen hr image imagetools link lists preview spellchecker wordcount quickbars',
  setup: (editor) => autoCompleter(editor, auto_complete_options),
  toolbar: ` preview  code  fullscreen | undo redo | formatselect fontsizeselect | bold italic strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist  blockquote |
        quickimage link hr restoredraft  spellchecker wordcount`,
  toolbar_mode: 'floating',
  toolbar_sticky: false
});
