import { toast } from 'react-toastify';
import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { setPageTitle } from 'store/actions/header';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { addOneAutomationToStore } from 'store/actions/automation';
import { DetailItem } from 'app/shared/info-section/item';
import useValidator from 'hooks/validator';
import { Spacer } from 'app/layouts/generic';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { TextArea } from 'app/shared/input/textarea';
import { useAutomationTemplateService } from 'hooks/automation/template';
import { PermissionsContext } from 'contexts/permissions';
import { useSubscriptionService } from 'hooks/sales/subscription';

const AutomationCreationForm = ({ setAutomationDataId }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { tenant_id } = useSelector((state) => state.user_data);
  const { permissions } = useContext(PermissionsContext);
  const { createTemplate, fetchTemplate, updateTemplate } = useAutomationTemplateService();
  const { fetchSubscription } = useSubscriptionService();

  const [automation_id, setAutomationId] = useState(0);
  const [data, setData] = useState({ title: '', description: '' });
  const [form_data, setFormData] = useState({});
  const [has_subscription, setHasSubscription] = useState(false);
  const [loading, setLoading] = useState(false);

  const automationValidationConfig = {
    title: {
      required: true
    }
  };

  const { errors, validateField, form_is_valid } = useValidator(automationValidationConfig);

  useEffect(() => {
    if (isNaN(id)) return;
    setAutomationId(() => id);
    fetchTemplate(id).then(({ template }) => {
      setData(template);
      handleSimpleInfoChange('title', template.title);
      handleSimpleInfoChange('description', template.description);
    });
  }, [id]);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Automations', path: '/automations' },
        {
          title: isNaN(id) ? 'Create Automation' : 'Update Automation',
          path: isNaN(id) ? '/automations/new' : `/automations/${id}`
        }
      ])
    );
    fetchSubscription({ query_string: 'plan_code=automation,startup&status=active' }).then(
      ({ subscriptions: [subscription] }) => {
        setHasSubscription(() => subscription && subscription.id);
      }
    );
  }, []);

  const handleSimpleInfoChange = (key, value = '') => {
    validateField(key, value);
    setFormData((curr_data) => ({ ...curr_data, [key]: value }));
  };

  const submitForm = async () => {
    const values = {
      ...form_data,
      tenant_id
    };

    setLoading(true);
    const { template } = automation_id
      ? await updateTemplate(automation_id, { data: values })
      : await createTemplate({ data: values });
    setLoading(false);

    if (!template) return;

    dispatch(addOneAutomationToStore({ ...data, ...template }));
    setAutomationDataId(template.id || automation_id);
    toast.success(`Automation ${automation_id ? 'updated' : 'created'} successfully`);

    if (!automation_id && template.id) {
      history(`/editor/automations/${template.id}`);
    }
  };

  return (
    <BasicInfoSection title="Basic details">
      <DetailItem title="Title">
        <SimpleInput
          input_id="title"
          value={form_data.title}
          onInput={(e) => handleSimpleInfoChange('title', e)}
          required
          error={errors.title}
        />
      </DetailItem>
      <DetailItem title="Description (max 200 characters)">
        <TextArea
          input_id="description"
          value={form_data.description}
          onInput={(e) => handleSimpleInfoChange('description', e)}
          rows={5}
        />
      </DetailItem>
      <Spacer multiple={2} />
      <Button
        icon_name="check_circle"
        onClick={submitForm}
        text={automation_id ? 'Update' : 'Save'}
        loading={loading}
        disabled={
          !has_subscription || !form_is_valid || loading || !permissions['automation:update']
        }
      />
    </BasicInfoSection>
  );
};

export default AutomationCreationForm;
