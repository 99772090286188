import { Spacer } from 'app/layouts/generic';
import { Button } from 'app/shared/button';
import { DateTimePicker } from 'app/shared/datepickers';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { getCurrentDayTimestamp } from 'app/shared/utils/date';
import { useEventLogService } from 'hooks/automation/eventlog';
import React, { useEffect, useState } from 'react';
import styles from './history.module.css';
import { HistoryViewPage } from './view';

export const EventHistory = ({ code }) => {
  const { fetchEventLogs } = useEventLogService();

  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [parsed_day, setParsedDay] = useState('');
  const [selected_day, setSelectedDay] = useState('');

  const loadMore = async (day) => {
    const end = day + 86400000 - 10;
    const { logs } = await fetchEventLogs({
      query_string: `code=${code}&day=${day}~${end}&page=${page}&population=25`
    });

    const hist_map = [...logs, ...history].reduce(
      (sac, hist) => ({ ...sac, [`${hist.day}.${hist.contact_email}`]: hist }),
      {}
    );
    setHistory(() => Object.values(hist_map));
    if (logs.length >= 25) {
      setPage((pg) => pg + 1);
    }
  };

  useEffect(() => {
    loadMore(parsed_day);
  }, [page, parsed_day]);

  useEffect(() => {
    setHistory(() => []);
    setPage(0);
    setParsedDay(selected_day ? Date.parse(selected_day) : getCurrentDayTimestamp());
  }, [selected_day]);

  return (
    <>
      <BasicInfoSection>
        <DateTimePicker
          className={styles.datepicker}
          showTime={false}
          onChange={setSelectedDay}
          value={selected_day}
        />
      </BasicInfoSection>
      <Spacer multiple={3} />
      <div className={styles.wrapper}>
        <header>
          <div className={styles.title}>Invocation history</div>
        </header>
        <div className={styles.body}>
          {history.length === 0 ? (
            <div className={styles.noRecord}>No record found.</div>
          ) : (
            <div className={styles.historyList}>
              {history.map((hist) => (
                <HistoryViewPage key={`${hist.day}-${hist.contact_email}`} data={hist} />
              ))}
              <Spacer multiple={3} />
              <Button onClick={() => setPage((pg) => pg + 1)} text="Load More" type="secondary" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
