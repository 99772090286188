import { Spacer } from 'app/layouts/generic';
import { Button } from 'app/shared/button';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { DetailItem } from 'app/shared/info-section/item';
import { toDateString } from 'app/shared/utils/date';
import { toReadableNumber } from 'app/shared/utils/number';
import { useSubscriptionService } from 'hooks/sales/subscription';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from './item.module.css';

export const ActiveSubscriptionItem = ({ data = {} }) => {
  const { cancelSubscription } = useSubscriptionService();

  const [show_confirmation, setShowConfirmation] = useState(false);
  const [subscription_info, setSubscriptionInfo] = useState({});

  useEffect(() => {
    const {
      title,
      metadata,
      expires_at,
      price: { amount, currency },
      renewal_period,
      resources
    } = data;
    if (!metadata || !metadata.module || !metadata.pricing) return;

    const { description } = metadata.module;
    setSubscriptionInfo({
      title,
      description,
      expires_at,
      amount,
      currency,
      renewal_period,
      resources
    });
  }, [data.id]);

  const handleCancellation = async (is_permitted) => {
    if (!is_permitted) return setShowConfirmation(false);
    toast.info('Cancelling subscription');
    await cancelSubscription(data.id);
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <h5>{data.title}</h5>
        <Spacer multiple={4} />
      </div>
      <div>
        <div>
          <DetailItem title="Cost">
            {subscription_info.currency?.code} {toReadableNumber(subscription_info.amount)}
          </DetailItem>
        </div>
        <div>
          <DetailItem title="Renewal Period">{subscription_info.renewal_period}ly</DetailItem>
        </div>
        <div>
          <DetailItem title="Expiration Date">
            {toDateString(subscription_info.expires_at)}
          </DetailItem>
        </div>
        <div>
          <Button
            className={styles.btn}
            text="Cancel subscription"
            onClick={() => setShowConfirmation(true)}
          />
        </div>
      </div>
      <ConfirmationDialog
        title="Cancel Subscription?"
        message="Are you sure you want to cancel your subscription. You will access to this module and this action cannot be undone."
        is_open={show_confirmation}
        callback={handleCancellation}
      />
    </div>
  );
};
