import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { objectIsEmpty } from 'app/shared/utils/general';
import { TreeContext } from 'contexts/automation';
import { useDeleteStep, useStepStoreActions } from 'hooks/automation/steps';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridColumn, GridRow } from 'app/layouts/grid';

import AutomationCreation from './form';
import { AutomationLayout } from './layout/index';
import styles from './automation.module.css';
import { AutomationExitWidget } from '../components/widget/exit';
import { Spacer } from 'app/layouts/generic';
import './automation.css';
import { AutomationTemplateStat } from '../components/stats';

const CreateAutomation = () => {
  const { id } = useParams();
  const { selected_step, setSelectedStep } = useContext(TreeContext);
  const { removeStep } = useStepStoreActions();
  const performDeleteStep = useDeleteStep();
  const [automation_id, setAutomationId] = useState(0);
  const [show_confirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setAutomationId(id);
  }, [id]);

  useEffect(() => {
    if (objectIsEmpty(selected_step) || !selected_step.action) return;
    const { action } = selected_step;
    if (action === 'delete') {
      setShowConfirmation(true);
    }
  }, [selected_step]);

  const handleConfirmation = async (permitted) => {
    if (permitted) {
      const { id } = selected_step.data;
      const payload = await performDeleteStep(id);
      if (payload) removeStep(id);
      setSelectedStep({});
    }

    setShowConfirmation(() => false);
  };

  return (
    <div className={styles.wrapper}>
      <GridRow>
        <GridColumn span={3}>
          <AutomationLayout automation_id={automation_id} />
        </GridColumn>
        <GridColumn>
          <div className={styles.inputWrapper}>
            <AutomationCreation setAutomationDataId={setAutomationId} />
            <Spacer multiple={4} />
            <AutomationTemplateStat template_id={automation_id} />
            <Spacer multiple={4} />
            <AutomationExitWidget />
          </div>
        </GridColumn>
      </GridRow>
      <ConfirmationDialog
        title="Delete Automation step"
        message="Are you sure you want to delete this automation step?"
        callback={handleConfirmation}
        is_open={show_confirmation}
      />
    </div>
  );
};

export default CreateAutomation;
