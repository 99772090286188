import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { useEventsService } from 'hooks/automation/event';
import React, { useEffect, useState } from 'react';

export const EventInput = ({ value = '', onChange = () => {} }) => {
  const { fetchEvents } = useEventsService();

  const [events, setEvents] = useState([]);
  const [selected_event, setSelectedEvent] = useState({});

  useEffect(() => {
    fetchEvents({ query_string: `type=user` }).then(({ events }) => {
      if (!events || !events.length) return;
      const parsed_events = events.map((event) => ({
        ...event,
        value: event.code,
        label: event.name
      }));
      setEvents(parsed_events);
      if (value) {
        setSelectedEvent(parsed_events.find((event) => event.code === value) || {});
      }
    });
  }, []);

  useEffect(() => {
    onChange(selected_event.code);
  }, [selected_event]);

  return (
    <>
      <DetailItem title="select event">
        <MultiSelect options={events} value={selected_event} onChange={setSelectedEvent} />
      </DetailItem>
    </>
  );
};
