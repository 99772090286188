import cx from 'classnames';
import { Icon } from 'assets/icons';
import React, { useEffect, useState } from 'react';
import styles from './landing.module.css';
import { useUserService } from 'hooks/iam/user';
import GmModal from 'app/shared/modal/modal';
import { OnboardingVideo } from '../video';
import { useTutorialService } from 'hooks/files/tutorial';

const CTA = ({ data = {} }) => {
  const { fetchTutorials } = useTutorialService();
  const [show_video, setShowVideo] = useState(false);
  const [video, setVideo] = useState({});

  useEffect(() => {
    if (!data.video_code) return;

    fetchTutorials({ query_string: `code=${data.video_code}` }).then(
      ({ tutorials: [tutorial] }) => {
        if (!tutorial) return;
        setVideo(tutorial);
      }
    );
  }, [data.video_code]);

  return (
    <>
      {data.type === 'link' && (
        <span className={styles.ctaWrapper}>
          <a href={data.path} target="_blank" rel="noreferrer">
            {data.text}
          </a>
          {data.icon && <Icon name={data.icon} />}
        </span>
      )}
      {data.type === 'video' && (
        <>
          <span className={styles.ctaWrapper} onClick={() => setShowVideo(true)}>
            <span href={data.path}>{data.text}</span>
            {data.icon && <Icon name={data.icon} />}
          </span>
          <GmModal show_modal={show_video} onClose={() => setShowVideo(false)}>
            <OnboardingVideo title={video.title} show_actions={false} url={video.urls?.video} />
          </GmModal>
        </>
      )}
    </>
  );
};

const Guide = ({ data = {}, control_config = {} }) => {
  const [is_completed, setIsCompleted] = useState(false);

  useEffect(() => {
    if (!data.control_key) {
      setIsCompleted(true);
      return;
    }
    setIsCompleted(control_config[data.control_key]);
  }, [control_config]);

  return (
    <div className={styles.guide}>
      <div className={styles.guideContentArea}>
        <Icon
          className={cx(styles.guideIcon, { [styles.isCompleted]: is_completed })}
          name="check_circle"
        />
        <div className={styles.guideContent}>
          <h5>{data.title}</h5>
          <p>{data.body}</p>
          <div className={styles.guideAction}>
            {(data.action || []).map((action) => (
              <CTA key={action.text} data={action} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LandingCard = ({ data = {}, control_config = {}, onHide = () => {} }) => {
  const { fetchUserData, updateUserData } = useUserService();

  const hideCard = async () => {
    const { user } = await fetchUserData();
    if (!user) return;

    const { onboarding } = user;
    const data_to_update = {
      ...onboarding,
      [data.key]: {
        ...onboarding[data.key],
        hidden: true
      }
    };

    const result = await updateUserData(user.id, { data: { onboarding: data_to_update } });
    if (result) onHide({ ...control_config, hidden: true });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainArea}>
        <div className={styles.mainContentArea}>
          <div className={styles.mainContent}>
            <h2>{data.main?.title}</h2>
            {(data.main?.body || []).map((p, i) => (
              <p key={i}>{p}</p>
            ))}
          </div>
          <div>
            <CTA data={data.main?.action} />
          </div>
        </div>
        <div className={styles.mainFooter} onClick={hideCard}>
          <Icon name="hide" />
          <span>Hide this guide forever</span>
        </div>
      </div>
      <div className={styles.guidesArea}>
        {(data.guides || []).map((guide) => (
          <Guide key={`guide-${Math.random()}`} control_config={control_config} data={guide} />
        ))}
      </div>
    </div>
  );
};
