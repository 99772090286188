import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { WebsiteIntegration } from './items/website';
import useScreenSize from 'hooks/size';
import { useModuleService } from 'hooks/sales/module';
import { IntegrationItem } from './items';
import { PageLoader } from 'app/shared/loader';

export const AllSubscriptions = () => {
  const dispatch = useDispatch();
  const { isLargeDesktop, isTablet } = useScreenSize();
  const { fetchModules } = useModuleService();

  const [is_loading, setIsLoading] = useState(true);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Subscriptions', path: '.' }]));
    setIsLoading(true);
    fetchModules().then(({ modules: srv_modules }) => {
      setModules(srv_modules);
      setIsLoading(false);
    });
  }, []);

  return is_loading ? (
    <PageLoader />
  ) : (
    <GridRow num_of_columns={isTablet ? 2 : isLargeDesktop ? 4 : 3}>
      <GridColumn>
        <WebsiteIntegration />
      </GridColumn>
      {modules.map((module) => (
        <GridColumn key={module.code}>
          <IntegrationItem data={module} />
        </GridColumn>
      ))}
    </GridRow>
  );
};
