import landing_data from './data/landing.json';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { Spacer } from 'app/layouts/generic';
import { RoleComponent } from './roles';
import { Route, Routes } from 'react-router-dom';
import { TeamsModule } from './teams';
import { InvitationsModule } from './invitations';
import { PermissionsContext } from 'contexts/permissions';
import { useRoleService } from 'hooks/iam/role';
import { useMembershipService } from 'hooks/iam/membership';
import { useUserService } from 'hooks/iam/user';
import { LandingCard } from 'app/shared/onboarding/landing';

export const UsersModuleDisplay = () => {
  const dispatch = useDispatch();
  const { permissions } = useContext(PermissionsContext);

  const { fetchUserData } = useUserService();
  const { fetchRoles } = useRoleService();
  const { fetchMemberships } = useMembershipService();

  const [onboarding_config, setOnboardingConfig] = useState({
    hidden: true,
    has_created_role: false,
    has_invited_teammate: false
  });

  const NAVITEMS = {
    invitations: {
      index: 1,
      title: 'Invitations',
      path: '/users/invitations'
    },
    teams: {
      index: 4,
      title: 'Team',
      path: '/users/team',
      hidden: !permissions['user_management:view']
    },
    roles: {
      index: 5,
      title: 'Roles',
      path: '/users/roles',
      hidden: !permissions['user_management:view']
    }
  };

  const [nav_items] = useState(NAVITEMS);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Users', path: '.' }]));

    Promise.all([fetchUserData(), fetchRoles(), fetchMemberships()]).then(
      ([{ user }, { roles }, { memberships }]) => {
        const { onboarding } = user;
        setOnboardingConfig((conf) => ({
          ...conf,
          hidden: !!(onboarding?.user_management && onboarding?.user_management.hidden),
          has_created_role: roles.length > 1,
          has_invited_teammate: memberships.length > 1
        }));
      }
    );
  }, [dispatch]);

  return (
    <>
      {!onboarding_config.hidden && (
        <>
          <LandingCard
            data={landing_data}
            control_config={onboarding_config}
            onHide={setOnboardingConfig}
          />
          <Spacer multiple={4} />
        </>
      )}
      <header>
        <SlidingNav nav_items={Object.values(nav_items).sort((a, b) => a.index - b.index)} />
      </header>
      <Spacer multiple={4} />
      <Routes>
        <Route path="invitations/*" element={<InvitationsModule />} />
        <Route path="team/*" element={<TeamsModule />} />
        <Route path="roles/*" element={<RoleComponent />} />
        <Route path="/*" element={<InvitationsModule />} />
      </Routes>
    </>
  );
};
