module.exports = {
  EVENT_ANNIVERSARY: 'anniversary',
  EVENT_BIRTHDAY: 'birthday',
  EVENT_CLICKED_LINK: 'clicked_link',
  EVENT_NEW_CONTACT: 'new_contact',
  EVENT_OPENED_CAMPAIGN: 'opened_campaign',
  EVENT_OPENED_TRANSACTIONAL: 'opened_transactional',
  EVENT_SUBSCRIPTION: 'joined_audience',
  EVENT_UNSUBSCRIPTION: 'left_audience',

  //
  EFFECT_ADD_CONTACT_TAG: 'add_tag_to_contact',
  EFFECT_REMOVE_CONTACT: 'delete_contact',
  EFFECT_SEND_TRANSACTIONAL: 'send_transactional_email',
  EFFECT_SEND_SMS: 'send_sms',
  EFFECT_SEND_WEB_PUSH: 'send_web_push_message',
  EFFECT_SUBSCRIBE: 'add_to_audience',
  EFFECT_UNSUBSCRIBE: 'remove_from_audience'
};
