import React from 'react';
import Spinner from '../spinners/spinner-50/spinner-50';
import styles from './loader.module.css';

export const PageLoader = () => {
  return (
    <div className={styles.wrapper}>
      <Spinner />
    </div>
  );
};
