import styles from './series.module.css';
import React, { useEffect, useState } from 'react';
import { useEventLogService } from 'hooks/automation/eventlog';
import { Icon } from 'assets/icons';
import { getCurrentDayTimestamp } from 'app/shared/utils/date';
import { flattenObject } from 'app/shared/utils/text';

export const DistributionTable = ({ event_code, name, onDelete = () => {} }) => {
  const today = getCurrentDayTimestamp();
  const seven_days_ago = today - 7 * 86400000;

  const { fetchEventLogs } = useEventLogService();

  const [table_data, setTableData] = useState([]);

  const fetchLogs = async (start, end, results = [], page = 0) => {
    try {
      const { logs } = await fetchEventLogs({
        query_string: `day=${start}~${end}&code=${event_code}&return_only=contact_email,occurrences.context.${name}&page=${page}&population=1000`
      });

      if (!logs.length) return results;
      return await fetchLogs(start, end, [...results, ...logs], page + 1);
    } catch (e) {
      return await fetchLogs(start, end, results, page);
    }
  };

  useEffect(() => {
    fetchLogs(seven_days_ago, today).then((logs) => {
      const all_matching_logs = {};
      const unique_matching_logs = {};

      for (const log of logs) {
        const { contact_email, occurrences } = log;
        for (const occurrence of occurrences) {
          const flattened_occurrence = flattenObject(occurrence, {}, true);
          if (!flattened_occurrence[`context.${name}`]) continue;

          const prop_name = flattened_occurrence[`context.${name}`];
          if (!all_matching_logs[prop_name]) {
            all_matching_logs[prop_name] = {
              all: 0
            };
          }

          if (!unique_matching_logs[prop_name]) unique_matching_logs[prop_name] = {};
          unique_matching_logs[prop_name][contact_email] = contact_email;

          all_matching_logs[prop_name].all += 1;
        }
      }

      const combined_logs = {};
      for (const key in all_matching_logs) {
        combined_logs[key] = {
          key,
          ...all_matching_logs[key],
          unique: Object.keys(unique_matching_logs[key]).length
        };
      }

      const parsed_data = Object.values(combined_logs);
      const sorted_data = parsed_data.sort((a, b) => b.all - a.all);
      setTableData(sorted_data.slice(0, 10));
    });
  }, [event_code, name]);

  return (
    <div className={styles.wrapper}>
      <header>
        <h5>Invocation by {name}</h5>
        <Icon className={styles.icon} name="trash" onClick={() => onDelete(name)} />
      </header>
      <div className={styles.body}>
        <table>
          <thead>
            <td colSpan={2}>Property Name</td>
            <td>Total Invocations</td>
            <td>Unique Invocations</td>
            <td>Invocations per user</td>
          </thead>
          {table_data.map((row) => (
            <tr key={row.key} className={styles.item}>
              <th colSpan={2}>{row.key}</th>
              <td>{row.all}</td>
              <td>{row.unique}</td>
              <td>{(row.all / row.unique).toFixed(2)}</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};
