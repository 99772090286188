import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as TransactionalService from 'services/transactional';
import { useNavigate } from 'react-router-dom';
import { urls } from 'services/_url';

export const useTransactionalService = () => {
  const { token } = useSelector((store) => store.user_data);
  const navigate = useNavigate();

  const creationOptions = [
    { icon_name: 'note', text: 'Plain text', onClick: () => navigate('/editor/transactional') },
    {
      icon_name: 'colors',
      text: 'Template builder',
      onClick: () => (window.location.href = `${urls.editor}/transactionals?t=${token}`)
    }
  ];

  const createTransactional = async (options = {}) => {
    const { error, payload } = await TransactionalService.create({ ...options, token });
    if (error) {
      toast.error('Unable to create transactional');
      return { transactional: {} };
    }

    toast.success('Transactional created successfully.');
    return { transactional: payload };
  };

  const deleteTransactionals = async (options = {}) => {
    const { error, payload } = await TransactionalService.deleteMany({
      ...options,
      token
    });

    if (error) {
      toast.error('could not delete transactionals.');
    }

    return payload;
  };

  const fetchTransactionalById = async (id, options = {}) => {
    const { error, payload } = await TransactionalService.readById(id, {
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to fetch transactional at this time.');
      return { transactional: {} };
    }

    return { transactional: payload };
  };

  const fetchTransactionals = async (options = {}) => {
    const { error, payload } = await TransactionalService.read({ ...options, token });
    if (error) {
      return { transactionals: [], error: 'Unable to fetch transactionals at this time.', size: 0 };
    }

    const {
      data: transactionals,
      meta: { size }
    } = payload;
    return { transactionals, size, error: null };
  };

  const searchTransactionals = async (keys, keyword, options = {}) => {
    const { error, payload } = await TransactionalService.search(keys, keyword, {
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to fetch transactionals at this time.');
      return { transactionals: [], size: 0 };
    }

    const {
      data: transactionals,
      meta: { size }
    } = payload;
    return { transactionals, size };
  };

  const updateTransactional = async (id, options = {}) => {
    const { error, payload } = await TransactionalService.updateById(id, { ...options, token });
    if (error) {
      toast.error('Unable to update transactional at this time.');
      return null;
    }

    toast.success('Transactional updated successfully.');
    return payload;
  };

  return {
    createTransactional,
    deleteTransactionals,
    fetchTransactionalById,
    fetchTransactionals,
    searchTransactionals,
    updateTransactional,
    creationOptions
  };
};
