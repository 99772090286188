import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { useAutomationConfigService } from 'hooks/automation/config';
import React, { useEffect, useState } from 'react';

export const DistributionTableCreationForm = ({ closeModal = () => {}, onSave = () => {} }) => {
  const { fetchAutomationConfigs, updateAutomationConfig } = useAutomationConfigService();

  const [config, setConfig] = useState({});
  const [properties, setProperties] = useState([]);
  const [is_saving, setIsSaving] = useState(false);
  const [selected_field, setSelectedField] = useState({});

  const submit = async () => {
    if (!selected_field.value) return;
    setIsSaving(true);
    const table_map = [...(config?.tables || []), selected_field.value].reduce(
      (sac, field) => ({ ...sac, [field]: field }),
      {}
    );

    const data = {
      options: {},
      data: { tables: Object.keys(table_map) }
    };

    const { config: payload } = await updateAutomationConfig({ data });
    setIsSaving(false);

    if (!payload) return;
    setConfig(payload);
    setSelectedField({});
    onSave(payload.tables || []);
    closeModal();
  };

  useEffect(() => {
    fetchAutomationConfigs().then(({ configs: [config] }) => {
      if (!config) return;
      const config_props = (config.properties || []).map((prop) => ({ label: prop, value: prop }));
      setConfig(config || {});
      setProperties(config_props);
    });
  }, [closeModal]);

  return (
    <>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Select property">
            <MultiSelect options={properties} value={selected_field} onChange={setSelectedField} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <Button text="Save" loading={is_saving} onClick={submit} />
        </GridColumn>
      </GridRow>
    </>
  );
};
