import landing_data from './data/landing.json';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import { SMSListPage } from './list';
import { useUserService } from 'hooks/iam/user';
import { useSMSService } from 'hooks/messaging/sms';
import { Spacer } from 'app/layouts/generic';
import { LandingCard } from 'app/shared/onboarding/landing';

export const SMSModule = () => {
  const dispatch = useDispatch();
  const { fetchSMSs } = useSMSService();
  const { fetchUserData } = useUserService();

  const [onboarding_config, setOnboardingConfig] = useState({
    hidden: true,
    has_created_message: false
  });

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Messaging', path: '/messaging/sms' },
        { title: 'SMS', path: '.' }
      ])
    );

    Promise.all([fetchUserData(), fetchSMSs()]).then(([{ user }, { size }]) => {
      const { onboarding } = user;
      setOnboardingConfig((conf) => ({
        ...conf,
        hidden: !!(onboarding?.sms && onboarding?.sms.hidden),
        has_created_message: !!size
      }));
    });
  }, []);

  return (
    <>
      {!onboarding_config.hidden && (
        <>
          <LandingCard
            data={landing_data}
            control_config={onboarding_config}
            onHide={setOnboardingConfig}
          />
          <Spacer multiple={4} />
        </>
      )}
      <Routes>
        <Route path="/*" element={<SMSListPage />} />
      </Routes>
    </>
  );
};
