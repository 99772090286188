import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SideNavItem from './item/item';
import { ReactComponent as AccountBalanceIcon } from 'assets/icons/site-name/account_balance.svg';
import { ReactComponent as NewTabIcon } from 'assets/icons/site-name/new-tab.svg';
import GmModal from 'app/shared/modal/modal';
import ListCreationForm from 'app/member/modules/mailing-lists/create/create';
import TopupForm from 'app/member/modules/wallet';
import { SiteIcon } from 'assets/icons';

import styles from './side-nav.module.css';
import { urls } from 'services/_url';
import useScreenSize from 'hooks/size';
import { useSelector } from 'react-redux';
import { PermissionsContext } from 'contexts/permissions';

const SideNav = (props) => {
  const { open_tray, onTrayClose } = props;
  const { permissions } = useContext(PermissionsContext);
  const veil = useRef();
  const { isDesktop, isMiniDesktop } = useScreenSize();
  const { user_management } = useSelector((state) => state.flags);

  const [show_topup_modal, setShowTopupModal] = useState(false);
  const [show_create_audience_modal, setShowCreateAudienceModal] = useState(false);

  useEffect(() => {
    if (open_tray) {
      openMenuTray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open_tray]);

  const closeMenuTray = () => {
    const tray = veil.current;
    let tray_left = 0;
    fadeOutTrayBackground();
    const slideOutAmination = setInterval(() => {
      if (tray_left > 100) {
        tray.style.left = '100%';
        clearInterval(slideOutAmination);
        onTrayClose();
        return;
      }

      tray_left += 10;
      tray.style.left = `-${tray_left}%`;
    }, 20);
  };

  const fadeInTrayBackground = () => {
    const tray = veil.current;
    let opacity = 0;
    const fadeInAnimation = setInterval(() => {
      if (opacity > 0.3) {
        tray.style.background = 'rgba(0, 0, 0, 0.35)';
        clearInterval(fadeInAnimation);
        return;
      }

      opacity += 0.1;
      tray.style.background = `rgba(0, 0, 0, ${opacity})`;
    }, 10);
  };

  const fadeOutTrayBackground = () => {
    const tray = veil.current;
    let opacity = 0.35;
    const fadeOutAnimation = setInterval(() => {
      if (opacity < 0) {
        tray.style.background = 'rgba(0, 0, 0, 0)';
        clearInterval(fadeOutAnimation);
        return;
      }

      opacity -= 0.1;
      tray.style.background = `rgba(0, 0, 0, ${opacity})`;
    }, 10);
  };

  const openMenuTray = () => {
    const tray = veil.current;
    let tray_left = 100;
    tray.style.background = 'rgba(0, 0, 0, 0)';
    const slideInAmination = setInterval(() => {
      if (tray_left < 0) {
        tray.style.left = 0;
        fadeInTrayBackground();
        clearInterval(slideInAmination);
        return;
      }

      tray_left -= 10;
      tray.style.left = `-${tray_left}%`;
    }, 20);
  };

  const openTopupModal = () => {
    closeMenuTray();
    setShowTopupModal(true);
  };

  return (
    <nav className={styles.sideNavWrapper} ref={veil} onClick={() => closeMenuTray()}>
      <div className={styles.appSideNav} onClick={(e) => e.stopPropagation()}>
        <div>
          <header>
            <Link to="/dashboard" className={styles.link}>
              <span className={styles.siteIcon}>
                <SiteIcon width={48} height={24} />
              </span>
              <div>
                <span className={styles.siteName}>Go-Mailer</span>
                <span className={styles.siteDot}>.</span>
              </div>
            </Link>
          </header>
          {/* <CreateButton actions={icon_new_actions} handleUserAction={handleUserAction} /> */}
          <section>
            <SideNavItem
              title="Dashboard"
              name="dashboard"
              path="/dashboard"
              onItemClick={closeMenuTray}
            />
            {permissions['campaign:view'] && (
              <SideNavItem
                title="Campaigns"
                name="campaign"
                path="/campaigns"
                onItemClick={closeMenuTray}
              />
            )}
            {
              <SideNavItem
                title="App Analytics"
                name="analysis"
                path="/insights/app"
                onItemClick={closeMenuTray}
              />
            }
            {permissions['automation:view'] && (isMiniDesktop || isDesktop) && (
              <SideNavItem
                title="Automations"
                name="automation"
                path="/automations/workflows"
                onItemClick={closeMenuTray}
              />
            )}
            {permissions['audience:view'] && (
              <SideNavItem
                title="Audiences"
                name="groups"
                path="/audiences"
                onItemClick={closeMenuTray}
              />
            )}
            {permissions['contact:view'] && (
              <SideNavItem
                title="Contacts"
                name="people"
                path="/contacts"
                onItemClick={closeMenuTray}
              />
            )}
            {permissions['transactional:view'] && (
              <SideNavItem
                title="Transactional Emails"
                name="transactional"
                path="/transactionals"
                onItemClick={closeMenuTray}
              />
            )}
            {
              <SideNavItem
                title="Messaging"
                name="sms"
                path="/messaging/sms"
                onItemClick={closeMenuTray}
              />
            }
            {permissions['billing_config:view'] && (
              <SideNavItem
                title="Subscriptions"
                name="extension"
                path="/subscriptions"
                onItemClick={closeMenuTray}
              />
            )}
            {user_management?.is_permitted && (
              <SideNavItem
                title="User Management"
                name="users"
                path="/users/invitations"
                onItemClick={closeMenuTray}
              />
            )}
            <SideNavItem
              title="Tutorials"
              name="book"
              onItemClick={closeMenuTray}
              path="/tutorials"
            />
            <SideNavItem
              title="Settings"
              name="settings"
              onItemClick={closeMenuTray}
              path="/settings/profile"
            />
          </section>
        </div>

        <div className={styles.cta}>
          <button onClick={openTopupModal}>
            <span className={styles.icon}>
              <AccountBalanceIcon />
            </span>
            <span className={styles.wallet}>Fund wallet</span>
          </button>
          <a href={`${urls.landing}/developers`} target="_blank" rel="noreferrer">
            <span className={styles.text}>Documentation</span>
            <NewTabIcon />
          </a>
        </div>
      </div>
      <div>
        <GmModal
          title="Create Audience"
          show_title={true}
          show_modal={show_create_audience_modal}
          onClose={() => setShowCreateAudienceModal(false)}
        >
          <ListCreationForm />
        </GmModal>
        <GmModal
          bodyClassName={styles.refillModal}
          title="Refill Wallet"
          show_title
          show_modal={show_topup_modal}
          onClose={() => setShowTopupModal(false)}
        >
          <TopupForm />
        </GmModal>
      </div>
    </nav>
  );
};

export default SideNav;
