import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';

import {
  addManyPushMessagesToStore,
  addOnePushMessageToStore,
  addPushMessageSearchResultsToStore
} from 'store/actions/push';
import { Button } from 'app/shared/button';
import GmModal from 'app/shared/modal/modal';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { truncateText } from 'app/shared/utils/general';
import { useSubscriptionService } from 'hooks/sales/subscription';
import { Badge } from 'app/shared/badge';
import { processDuplicateName } from 'app/shared/utils/mailing';
import { WebPushMessageForm } from '../form';
import { useWebPushService } from 'hooks/messaging/push/web';

export const WebPushListPage = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { fetchSubscription } = useSubscriptionService();
  const { createPushMessage, fetchPushMessages, searchPushMessage, updatePushMessage } =
    useWebPushService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const messages_in_store = useSelector((state) => state.push);

  const [messages, setMessages] = useState([]);
  const [has_subscription, setHasSubscription] = useState(false);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [show_cancel_confirmation, setShowCancelConfirmation] = useState(false);
  const [show_creation_modal, setShowCreationModal] = useState(false);
  const [message_to_edit, setMessageToEdit] = useState({});
  const [message_to_cancel, setMessageToCancel] = useState({});
  const [total_messages, setTotalMessages] = useState(0);

  useEffect(() => {
    fetchSubscription({ query_string: 'plan_code=web_push,startup&status=active' }).then(
      ({ subscriptions: [subscription] }) => {
        setHasSubscription(subscription && subscription.id);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const parsed_campaigns = Object.values(messages_in_store);
    setMessages(() => parsed_campaigns);
  }, [messages_in_store]);

  const config = {
    actions: {
      single: (message) => [
        {
          value: 'view',
          label: 'View'
        },
        {
          value: 'edit',
          label: 'Edit'
        },
        { label: 'Clone', value: 'clone' },
        {
          label: 'Cancel',
          value: 'cancel',
          hidden: message.status !== 'queued'
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Title',
        key: 'title',
        isTitle: true,
        formatter: (value) => truncateText(value, 25) || ''
      },
      {
        title: 'Sender ID',
        key: 'sender_id',
        isTagline: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Status',
        key: 'status',
        formatter: (value) => (
          <Badge
            type={value === 'queued' ? 'orange' : value === 'processed' ? 'green' : 'red'}
            text={value}
          />
        ),
        isBadge: true
      },
      {
        title: 'Type',
        key: 'type',
        formatter: (value) => (
          <Badge type={value === 'campaign' ? 'blue' : 'orange'} text={value} />
        ),
        isBadge: true
      },
      {
        title: 'Send date',
        key: 'schedule',
        formatter: (value) => {
          if (!value || !value?.date) return 'N/A';
          return new Date(value.date).toDateString();
        },
        isMetadata: true
      }
    ],
    is_search_mode,
    items: messages.sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'title,message,sender_id',
    search_text: ''
  };

  const cancelQueued = async (item_data) => {
    if (!item_data.schedule || !item_data.schedule.date) return;

    const now = Date.now();
    const schedule_date = Date.parse(item_data.schedule.date);

    if (schedule_date < now || schedule_date - now < 5 * 60 * 1000) {
      toast.info('Cannot cancel message');
      return;
    }

    toast.info('Canceling Message...');
    const result = await updatePushMessage(item_data.id, { data: { status: 'draft' } });
    if (result) dispatch(addOnePushMessageToStore({ ...item_data, status: 'draft' }));
  };

  const duplicate = async (message_data) => {
    const data = {
      audiences: message_data.audiences,
      message: message_data.message,
      schedule: message_data.schedule,
      sender_id: message_data.sender_id,
      sender_image: message_data.sender_image,
      status: 'draft',
      title: processDuplicateName(message_data.title, 'copy', '-'),
      type: message_data.type
    };

    toast.info('Cloning Message...');
    const { message } = await createPushMessage({ data });

    if (message) {
      dispatch(addOnePushMessageToStore(message));
      toast.success('Cloning successful.');
    }
  };

  const handleCancelConfirmation = (permitted) => {
    if (permitted) {
      cancelQueued(message_to_cancel);
    }

    setMessageToCancel({});
    setShowCancelConfirmation(false);
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        case 'edit':
          setMessageToEdit(data);
          setShowCreationModal(true);
          break;
        case 'clone':
          duplicate(data);
          break;
        case 'cancel':
          setMessageToCancel(data);
          setShowCancelConfirmation(true);
          break;
        default:
          history(`/messaging/push/web/${data.id}`);
      }
    }
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history(`/messaging/push/web/${id}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { messages, size } = await fetchPushMessages({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(addManyPushMessagesToStore(messages));
      setTotalMessages(size);
    } catch (e) {
      dispatch(addManyPushMessagesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { messages, size } = await searchPushMessage(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      setTotalMessages(size);
      if (page === 0) return dispatch(addPushMessageSearchResultsToStore(messages));
      dispatch(addManyPushMessagesToStore(messages));
    } catch (e) {
      dispatch(addManyPushMessagesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button
        icon_name="add"
        text="New Message"
        disabled={!has_subscription}
        onClick={() => setShowCreationModal(true)}
      />
    </>
  );

  return (
    <PageContentWrapper>
      <GridRow>
        <GridColumn span={4}>
          {is_mobile_view ? (
            <MobileDatatable
              config={config}
              action={handleDatatableAction}
              onClick={handleItemClick}
              onListModeChange={setSearchMode}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              showHeader
            />
          ) : (
            <WebDatatable
              config={{
                ...config,
                total_count: total_messages
              }}
              action={handleDatatableAction}
              onClick={handleItemClick}
              checkbox
              loading_data={loading}
              table_actions={table_actions}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
            />
          )}
          <ConfirmationDialog
            title="Cancel Message"
            message="Are you sure you want to cancel this message?"
            callback={handleCancelConfirmation}
            is_open={show_cancel_confirmation}
          />
          <GmModal
            show_modal={show_creation_modal}
            show_title
            title="Create message"
            onClose={() => setShowCreationModal(false)}
          >
            <WebPushMessageForm message_data={message_to_edit} />
          </GmModal>
        </GridColumn>
      </GridRow>
    </PageContentWrapper>
  );
};
