/** */
import cx from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addDataToStore, logout } from 'store/actions/user-data';

import ContextMenu from 'app/shared/datatable/context-menu/context-menu';
import GmModal from 'app/shared/modal/modal';
import ListCreationForm from 'app/member/modules/mailing-lists/create/create';
import Notifications from 'app/member/modules/header/notification/notifications';
import Breadcrumbs from 'app/shared/breadcrumbs/breadcrumbs';

import { CreateButton } from 'app/shared/button/create';
import { Icon } from 'assets/icons';
import TopupForm from '../wallet';
import styles from './header.module.css';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { NoticeBanner } from './banner';
import { addMetadata } from 'store/actions/metadata';
import { Button } from 'app/shared/button';
import { CreateContactModal } from '../contacts/create';
import { OptionsModal } from 'app/shared/option-modal';
import { useTransactionalService } from 'hooks/transactional';
import { useCampaignService } from 'hooks/mailing/campaign';
import ImportContact from '../contacts/import/import';
import { toReadableNumber } from 'app/shared/utils/number';
import useScreenSize from 'hooks/size';
import { SwitchOrganizationModal } from 'app/member/orgamization/switch';
import { PermissionsContext } from 'contexts/permissions';
import { useGuestService } from 'hooks/guest';
import { useMailingConfigService } from 'hooks/mailing/configuration';
import { useWalletService } from 'hooks/sales/wallet';
import { useUserService } from 'hooks/iam/user';
import { useTutorialService } from 'hooks/files/tutorial';
import { OnboardingVideo } from 'app/shared/onboarding/video';
import { useSubscriptionService } from 'hooks/sales/subscription';

const WalletBalance = () => {
  const wallet_in_store = useSelector((store) => store.wallet);

  const [wallet, setWallet] = useState({});

  useEffect(() => {
    setWallet(wallet_in_store);
  }, [wallet_in_store]);

  return (
    <aside className={styles.wallet}>
      <small>Available credits:</small>
      <span>
        <b>{toReadableNumber(wallet.balance)}</b>
      </span>
    </aside>
  );
};

const MemberAreaHeader = ({ onHamburgerClick }) => {
  const { isMobile, isTablet } = useScreenSize();
  const { permissions } = useContext(PermissionsContext);
  const dispatch = useDispatch();
  const history = useNavigate();

  const { creationOptions } = useCampaignService();
  const { logOut } = useGuestService();
  const { fetchConfiguration } = useMailingConfigService();
  const { updateUserData } = useUserService();
  const { fetchSubscription } = useSubscriptionService();
  const { creationOptions: trasactionalCreationOptions } = useTransactionalService();
  const { fetchTutorials } = useTutorialService();
  const { checkBalanceSufficience, fetchWallet } = useWalletService();

  const { page_title } = useSelector((state) => state.header);
  const { low_balance_display } = useSelector((state) => state.metadata);
  const user_data = useSelector((state) => state.user_data);
  const { firstname, lastname } = user_data;

  const [banner_message, setBannerMessage] = useState('');
  const [has_exceeded_campaign_limit, setHasExceededCampaignLimit] = useState(false);
  const [has_exceeded_automation_limit, setHasExceededAutomationLimit] = useState(false);
  const [is_dkim_set, setIsDKIMSet] = useState(false);
  const [show_create_audience_modal, setShowCreateAudienceModal] = useState(false);
  const [show_creation_modal, setShowCreationModal] = useState(false);
  const [show_tx_creation_options, setShowTxCreationOptions] = useState(false);
  const [show_creation_options, setShowCreationOptions] = useState(false);
  const [show_switch_account_modal, setShowSwitchAccountModal] = useState(false);
  const [show_topup_modal, setShowTopupModal] = useState(false);
  const [show_upload_modal, setShowUploadModal] = useState(false);
  const [show_welcome_video, setShowWelcomeVideo] = useState(false);
  const [welcome_video, setWelcomeVideo] = useState({});
  // const { width } = useScreenSize();

  useEffect(() => {
    if (low_balance_display) return;
    checkBalanceSufficience().then((is_sufficient) => {
      if (is_sufficient || low_balance_display) return;
      setBannerMessage(
        () => 'Your available email units are running low. Please top up your wallet.'
      );

      dispatch(addMetadata({ low_balance_display: true }));
    });
  }, []);

  useEffect(() => {
    fetchConfiguration().then(({ configurations: [config] }) => {
      if (!config) return;

      const { dns } = config;
      setIsDKIMSet(() => dns && !!dns.length);
    });
    fetchSubscription({ query_string: 'plan_code=campaign&status=active' }).then(
      ({ subscriptions: [subscription] }) => {
        if (!subscription) return;
        const {
          resources: { usage, limit }
        } = subscription;
        setHasExceededCampaignLimit(() => limit !== 0 && usage >= limit);
      }
    );
    fetchSubscription({ query_string: 'plan_code=automation&status=active' }).then(
      ({ subscriptions: [subscription] }) => {
        if (!subscription) return;
        const {
          resources: { usage, limit }
        } = subscription;
        setHasExceededAutomationLimit(() => limit !== 0 && usage >= limit);
      }
    );
  }, []);

  useEffect(() => {
    const { onboarding } = user_data;
    if (!onboarding) {
      updateUserData(user_data.id, { data: { onboarding: { has_watched_welcome_video: false } } });
      return;
    }

    fetchTutorials({ query_string: 'bool=is_welcome&sort_by=-created_on' }).then(
      ({ tutorials: [tutorial] }) => {
        setWelcomeVideo(tutorial || {});
        if (!onboarding.has_watched_welcome_video) setShowWelcomeVideo(true);
      }
    );
  }, [user_data.id]);

  useMemo(() => {
    fetchWallet();
  }, [user_data.tenant_id]);

  const icon_user_actions = [
    {
      action: '',
      label: <WalletBalance />,
      className: 'wallet-balance-item'
    },
    {
      label: <span key={`${firstname} ${lastname}`}>{`${firstname} ${lastname}`} </span>,
      value: 'profile'
    },
    { label: 'Create an Organization', value: 'create_organization' },
    { label: 'Switch Organization', value: 'switch_organization' },
    { label: 'Refill Wallet', value: 'Refill Wallet' },
    { label: 'Log Out', value: 'Log Out' }
  ];

  const handleLogout = async () => {
    await logOut();
    dispatch(logout({}));
  };

  const handleUserAction = (data) => {
    if (typeof data !== 'string') return;

    switch (data.toLowerCase()) {
      case 'campaign':
        history('/editor/campaign');
        break;
      case 'contact':
        history('/contacts/new/single');
        break;
      case 'audience':
        setShowCreateAudienceModal(true);
        break;
      case 'refill wallet':
        setShowTopupModal(true);
        break;
      case 'create_organization':
        history('/organizations');
        break;
      case 'switch_organization':
        setShowSwitchAccountModal(() => true);
        break;
      case 'transactional email':
        history('/editor/transactional/');
        break;
      case 'log out':
        handleLogout();
        history('/login');
        break;
      case 'profile':
        history('/settings');
        break;
      default:
    }
  };

  const handleVideoSkip = async () => {
    const data = { onboarding: { has_watched_welcome_video: true } };
    const result = await updateUserData(user_data.id, { data });

    if (result) {
      dispatch(addDataToStore({ ...user_data, ...data }));
    }
    setShowWelcomeVideo(() => false);
  };

  const create_actions = [
    { label: 'Create Contact', value: 'create_contact', hidden: !permissions['contact:create'] },
    { label: 'Import Contact', value: 'import_contact', hidden: !permissions['contact:create'] },
    { label: 'Create Audience', value: 'create_audience', hidden: !permissions['audience:create'] }
  ];

  if (!isMobile && !isTablet) {
    create_actions.push(
      {
        label: 'Create Campaign',
        value: 'create_campaign',
        hidden: !permissions['campaign:create'] || has_exceeded_campaign_limit
      },
      {
        label: 'Create Transactional',
        value: 'create_transactional',
        hidden: !permissions['transactional:create']
      },
      {
        label: 'Create Automation',
        value: 'create_automation',
        hidden: !permissions['automation:create'] || has_exceeded_automation_limit
      }
    );
  }

  const handleCreateAction = (data) => {
    if (typeof data !== 'string') return;

    switch (data.toLowerCase()) {
      case 'create_contact':
        setShowCreationModal(true);
        break;
      case 'create_automation':
        history('/editor/automations/new');
        break;
      case 'create_transactional':
        setShowTxCreationOptions(true);
        break;
      case 'create_campaign':
        setShowCreationOptions(true);
        break;
      case 'create_audience':
        setShowCreateAudienceModal(true);
        break;
      case 'import_contact':
        setShowUploadModal(true);
        break;
      default:
    }
  };

  return (
    <PageContentWrapper className={styles.bgColor}>
      <header className={cx(styles.wrapper, 'shadow-sd')}>
        <div>
          <div className={styles.pageTitle}>
            <span className={styles.menuIcon} onClick={onHamburgerClick}>
              <Icon name="menu" />
            </span>

            {page_title?.length && (
              <span className={styles.title}>
                <Breadcrumbs crumbs={page_title} />
              </span>
            )}
          </div>

          <div className={styles.actions}>
            <ContextMenu
              actions={create_actions.filter((action) => !action.hidden)}
              callback={handleCreateAction}
              text={<Button text={'Create'} className={styles.greenBadge} />}
            />
            <CreateButton
              actions={create_actions.filter((action) => !action.hidden)}
              handleUserAction={handleCreateAction}
            />
            <Notifications />
            <div className={styles.profileIcon}>
              <ContextMenu
                actions={icon_user_actions}
                callback={handleUserAction}
                text={<Icon name="profile" />}
                position="right"
              />
            </div>
          </div>
        </div>
      </header>
      {banner_message && (
        <NoticeBanner message={banner_message} onClose={() => setBannerMessage('')} />
      )}
      <div>
        <GmModal
          title="Create Audience"
          show_title={true}
          show_modal={show_create_audience_modal}
          onClose={() => setShowCreateAudienceModal(false)}
        >
          <ListCreationForm />
        </GmModal>
        <GmModal
          title="Refill Wallet"
          show_title
          show_modal={show_topup_modal}
          onClose={() => setShowTopupModal(false)}
        >
          <TopupForm />
        </GmModal>
        <GmModal
          bodyClassName={styles.importsModal}
          title="Create Contact"
          show_title={true}
          show_modal={show_creation_modal}
          onClose={() => setShowCreationModal(false)}
        >
          <CreateContactModal />
        </GmModal>
        <GmModal
          title="Choose creation mode"
          show_modal={show_tx_creation_options}
          onClose={() => setShowTxCreationOptions(false)}
          bodyClassName={styles.optionsModal}
          show_title
        >
          <OptionsModal options={trasactionalCreationOptions} is_dkim_set={is_dkim_set} />
        </GmModal>
        <GmModal
          title="Choose creation mode"
          show_modal={show_creation_options}
          onClose={() => setShowCreationOptions(false)}
          bodyClassName={styles.optionsModal}
          show_title
        >
          <OptionsModal options={creationOptions} is_dkim_set={is_dkim_set} />
        </GmModal>
        <GmModal
          bodyClassName={styles.importsModal}
          title="Import Contacts"
          show_title={true}
          show_modal={show_upload_modal}
          onClose={() => setShowUploadModal(false)}
        >
          <ImportContact />
        </GmModal>
        <GmModal
          title="Switch Organization"
          show_title={true}
          show_modal={show_switch_account_modal}
          onClose={() => setShowSwitchAccountModal(false)}
        >
          <SwitchOrganizationModal />
        </GmModal>
        <GmModal
          title={welcome_video?.title}
          show_title
          show_modal={show_welcome_video}
          onClose={() => setShowWelcomeVideo(false)}
        >
          <OnboardingVideo
            title={welcome_video?.title}
            url={show_welcome_video ? welcome_video?.urls?.video : ''}
            onSkip={handleVideoSkip}
          />
        </GmModal>
      </div>
    </PageContentWrapper>
  );
};

export default MemberAreaHeader;
