import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import landing_data from './data/landing.json';

import { setPageTitle } from 'store/actions/header';
import {
  addManyTransactionalsToStore,
  addOneTransactionalToStore,
  addTransactionalSearchResults,
  removeManyTransactionalFromStore
} from 'store/actions/transactional';
import { Button } from 'app/shared/button';
import GmModal from 'app/shared/modal/modal';
import { OptionsModal } from 'app/shared/option-modal';
import { urls } from 'services/_url';
import styles from '../transactional.module.css';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Spacer } from 'app/layouts/generic';
import { TransactionalStatBar } from './statbar';
import { useTransactionalService } from 'hooks/mailing/transactional';
import useScreenSize from 'hooks/size';
import { processDuplicateCode, processDuplicateName } from 'app/shared/utils/mailing';
import { PermissionsContext } from 'contexts/permissions';
import { useMailingConfigService } from 'hooks/mailing/configuration';
import { useMailActionService } from 'hooks/mailaction';
import { useMailLogService } from 'hooks/maillog';
import { useUserService } from 'hooks/iam/user';
import { LandingCard } from 'app/shared/onboarding/landing';

const TransactionalEmailList = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const { token } = useSelector((state) => state.user_data);
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const transactionals_in_store = useSelector((state) => state.transactionals);
  const { permissions } = useContext(PermissionsContext);
  const { fetchMailActions } = useMailActionService();
  const { fetchMailLogs } = useMailLogService();
  const { fetchConfiguration } = useMailingConfigService();
  const { fetchUserData } = useUserService();
  const {
    createTransactional,
    deleteTransactionals,
    fetchTransactionals,
    creationOptions,
    searchTransactionals
  } = useTransactionalService();

  const [transactionals, setTransactionals] = useState([]);
  const [total_transactionals, setTotalTransactionals] = useState(0);
  const [is_dkim_set, setIsDKIMSet] = useState(false);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading_data, setLoadingData] = useState(true);
  const [onboarding_config, setOnboardingConfig] = useState({
    hidden: true,
    has_created_transactional: false
  });
  const [show_creation_options, setShowCreationOptions] = useState(false);
  const [show_confirmation, setShowConfirmation] = useState(false);
  const [transactionals_to_delete, setTransactionalsToDelete] = useState([]);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Transactional Emails', path: '.' }]));
    fetchConfiguration().then(({ configurations: [config] }) => {
      if (!config) return;

      const { dns } = config;
      setIsDKIMSet(() => dns && !!dns.length);
    });

    fetchUserData().then(({ user }) => {
      const { onboarding } = user;
      setOnboardingConfig((conf) => ({
        ...conf,
        hidden: !!(onboarding?.transactional && onboarding?.transactional.hidden)
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTransactionals(Object.values(transactionals_in_store));
  }, [transactionals_in_store]);

  const config = {
    actions: {
      single: () => [
        { label: 'Clone', value: 'clone', hidden: !permissions['transactional:create'] },
        {
          label: 'Edit',
          value: 'open',
          hidden: !permissions['transactional:update']
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        isTitle: true
      },
      {
        title: 'Code',
        key: 'code',
        isTagline: true
      },
      {
        title: 'Total Sends',
        key: 'total_sends',
        formatter: (value) => {
          return value || '0';
        }
      },
      {
        title: 'Total Opens',
        key: 'total_opened',
        formatter: (value) => {
          return value || '0';
        }
      },
      {
        title: 'Date created',
        key: 'created_on',
        formatter: (value) => new Date(value).toDateString(),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: transactionals.sort((a, b) => {
      let difference = b.time_stamp - a.time_stamp;
      if (difference === 0) {
        difference = b.id - a.id;
      }

      return difference;
    }),
    search_key: 'name',
    search_text: ''
  };

  const handleClone = async (payload) => {
    const data = {
      body: payload.body,
      is_advanced: payload.is_advanced,
      retry_email: payload.retry_email,
      sender_email: payload.sender_email,
      sender_name: payload.sender_name,
      subject: payload.subject,
      template: payload.template,
      tenant_id: payload.tenant_id,
      code: processDuplicateCode(payload.code, 'COPY', '_'),
      name: processDuplicateName(payload.name, 'copy', '-')
    };

    toast.info('Cloning transactional');
    const { transactionals } = await searchTransactionals('code', {
      query_string: `keyword=${data.code}`
    });
    const existing_record = transactionals.find(
      (transactional) => transactional.code === data.code
    );
    if (existing_record && existing_record.id) return toast.error('Code already in use.');

    const { transactional } = await createTransactional({ data });

    if (transactional && transactional.id) {
      dispatch(addOneTransactionalToStore({ ...transactional }));
    }
  };

  const handleDelete = async (ids = []) => {
    const data = {
      options: {
        id: ids.join()
      }
    };
    const result = deleteTransactionals({ data });

    if (result) {
      dispatch(removeManyTransactionalFromStore(ids));
      toast.success('Deletion successful.');
    }
  };

  const handleConfirmation = (permitted) => {
    if (permitted) {
      handleDelete(transactionals_to_delete);
    }

    setTransactionalsToDelete([]);
    setShowConfirmation(false);
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;
    if (type === 'single') {
      switch (name) {
        case 'clone':
          handleClone(data);
          break;
        case 'Delete':
          setTransactionalsToDelete([data.id]);
          setShowConfirmation(true);
          break;
        default:
          if (data.is_advanced) {
            window.location.href = `${urls.editor}/transactionals/${data.id}?t=${token}`;
            return;
          }
          history(`/editor/transactional/${data.id}`);
      }
    }

    if (type === 'bulk') {
      switch (name) {
        case 'Delete':
          setTransactionalsToDelete(() => data.map((transactional) => transactional.id));
          setShowConfirmation(true);
          break;
        default:
      }
    }
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history(`/transactionals/${id}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoadingData(true);
      const { transactionals, size } = await fetchTransactionals({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });
      setOnboardingConfig((conf) => ({ ...conf, has_created_transactional: !!size }));

      const total_sends_request = transactionals.map(async (trans) => {
        return fetchMailLogs({
          query_string: `class=transactional&resource_id=${trans.id}&status=sent&count=1`
        });
      });

      const total_opens_request = transactionals.map(async (trans) => {
        return fetchMailActions({
          query_string: `resource_id=${trans.id}&resource_type=transactional&action=opened&count=1`
        });
      });

      const total_sends_response = await Promise.all(total_sends_request);
      const total_opens_response = await Promise.all(total_opens_request);

      const transactional_data = transactionals.map((trans, index) => ({
        ...trans,
        total_sends: total_sends_response[index].size,
        total_opened: total_opens_response[index].size
      }));

      setTotalTransactionals(size);
      dispatch(addManyTransactionalsToStore(transactional_data));
    } catch (e) {
      dispatch(addManyTransactionalsToStore([]));
    } finally {
      setLoadingData(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoadingData(true);
      const { transactionals, size } = await searchTransactionals(keys, keyword, {
        token,
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      setTotalTransactionals(size);
      if (page === 0) return dispatch(addTransactionalSearchResults(transactionals));
      dispatch(addManyTransactionalsToStore(transactionals));
    } catch (e) {
      dispatch(addManyTransactionalsToStore([]));
    } finally {
      setLoadingData(false);
    }
  };

  const table_actions = (
    <>
      <Button
        icon_name="add"
        text="Transactional"
        onClick={() => setShowCreationOptions(true)}
        disabled={!permissions['transactional:create']}
      />
    </>
  );

  return (
    <PageContentWrapper>
      {!isMobile && (
        <>
          {!onboarding_config.hidden && (
            <>
              <LandingCard
                data={landing_data}
                control_config={onboarding_config}
                onHide={setOnboardingConfig}
              />
              <Spacer multiple={4} />
            </>
          )}
          <TransactionalStatBar />
          <Spacer multiple={4} />
        </>
      )}
      <GridRow>
        <GridColumn span={4}>
          {is_mobile_view ? (
            <MobileDatatable
              config={config}
              action={handleDatatableAction}
              onClick={handleItemClick}
              onListModeChange={setSearchMode}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              showHeader
            />
          ) : (
            <div>
              <WebDatatable
                config={{
                  ...config,
                  total_count: total_transactionals
                }}
                action={handleDatatableAction}
                onClick={handleItemClick}
                checkbox
                loading_data={loading_data}
                table_actions={table_actions}
                onDataRequest={handleDataRequest}
                onSearchRequest={handleSearchRequest}
              />
            </div>
          )}
          <ConfirmationDialog
            title="Delete transactional email"
            message="Are you sure you want to delete this Transactional?"
            callback={handleConfirmation}
            is_open={show_confirmation}
          />
          <GmModal
            title="Choose creation mode"
            show_modal={show_creation_options}
            onClose={() => setShowCreationOptions(false)}
            bodyClassName={styles.optionsModal}
            show_title
          >
            <OptionsModal is_dkim_set={is_dkim_set} options={creationOptions} />
          </GmModal>
        </GridColumn>
      </GridRow>
    </PageContentWrapper>
  );
};

export default TransactionalEmailList;
