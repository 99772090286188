// eslint-disable-file no-template-curly-in-string
import { urls } from 'services/_url';
export const generatePixel = (tenant_id) => {
  return `<!-- Go-Mailer Pixel -->
  <script>
    !function (g, o, m, a, i, l) {
      if (g.gm) return
      a = g.gm = function () {
        a.execute ? a.execute.apply(a, arguments) : a.queue.push(arguments)
      }, a.version = '1.0', a.queue = [];

      i = o.createElement(m), i.async = true, i.src = "${urls.automata}/files/js/gm.js"
      l = o.getElementsByTagName(m)[0], l.parentNode.insertBefore(i, l)
    }(window, document, 'script')
    gm('uid', ${tenant_id})
    gm('event', '${urls.automata}/api/v1/events/public/trigger')
  </script>
  <!-- End Go-Mailer Pixel -->
  `;
};
