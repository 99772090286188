import React from 'react';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { Spacer } from 'app/layouts/generic';
import { Route, Routes } from 'react-router-dom';
import { ActiveSubcriptions } from './active';
import { AllSubscriptions } from './all';

const SubscriptionModule = () => {
  const nav_options = [
    {
      title: 'All Subscriptions',
      path: '/subscriptions'
    },
    {
      title: 'Active Subscriptions',
      path: '/subscriptions/active'
    }
  ];

  return (
    <PageContentWrapper>
      <SlidingNav nav_items={nav_options} />
      <Spacer multiple={4} />
      <Routes>
        <Route path="active" element={<ActiveSubcriptions />} />
        <Route path="*" element={<AllSubscriptions />} />
      </Routes>
    </PageContentWrapper>
  );
};

export default SubscriptionModule;
