import { GridColumn, GridRow } from 'app/layouts/grid';
import { InfoBanner } from 'app/shared/info-banner';
import { DetailItem } from 'app/shared/info-section/item';
import { CodeWrapper } from 'app/shared/wrappers/code';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePixel } from './helper';

export const PixelConfig = () => {
  const { tenant_id } = useSelector((store) => store.user_data);
  const [snippet, setSnippet] = useState();

  useEffect(() => {
    setSnippet(generatePixel(tenant_id));
  }, [tenant_id]);

  return (
    <>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <InfoBanner message="Copy the following the snippet to your website's HTML <head></head> tag." />
        </GridColumn>
        <GridColumn>
          <DetailItem title="Pixel code">
            <CodeWrapper text={snippet} />
          </DetailItem>
        </GridColumn>
      </GridRow>
    </>
  );
};
