import {
  EVENT_BIRTHDAY,
  EVENT_CLICKED_LINK,
  EVENT_NEW_CONTACT
} from 'app/shared/constants/automation';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { useEventsService } from 'hooks/automation/event';
import { useResourceQuery } from 'hooks/automation/resources';
import React, { useEffect, useState } from 'react';
import { NeuronLinkTriggerConfig } from './link';

export const NeuronTrigger = ({ data = null, errors = {}, onChange = () => {} }) => {
  const { fetchEvents, isCustomEvent } = useEventsService();
  const { fetchResources } = useResourceQuery();

  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [selected_resource, setSelectedResource] = useState(null);
  const [selected_trigger, setSelectedTrigger] = useState(null);
  const [show_link_config, setShowLinkConfig] = useState(false);

  useEffect(() => {
    fetchEvents().then(({ events: raw_events }) => {
      const parsed_events = raw_events.map((ev) => ({ ...ev, label: ev.name, value: ev.code }));
      setEvents(() => parsed_events);
      if (data) {
        const { event: existing_event } = data;
        setSelectedTrigger(
          () => parsed_events.find((event) => event.code === existing_event) || null
        );
      }
    });
  }, [data]);

  useEffect(() => {
    if (!selected_trigger) return;
    if (selected_trigger.value === EVENT_CLICKED_LINK) {
      setShowLinkConfig(() => true);
      return;
    }

    setShowLinkConfig(() => false);
    if (selected_trigger.value === EVENT_NEW_CONTACT) return setSelectedResource({ value: 'none' });
    if (selected_trigger.value === EVENT_BIRTHDAY) return setSelectedResource({ value: 'any' });
    if (isCustomEvent(selected_trigger)) return setSelectedResource({ value: 'any' });

    handleResourceRequest(selected_trigger);
  }, [selected_trigger?.value]);

  useEffect(() => {
    handleChange();
  }, [selected_resource?.value]);

  const cannot_select_resource =
    selected_trigger?.value === EVENT_BIRTHDAY ||
    selected_trigger?.value === EVENT_NEW_CONTACT ||
    isCustomEvent(selected_trigger);

  const handleLinkParentSelection = (selection = {}) => {
    if (!selection.target) return;
    handleResourceRequest(selected_trigger, selection);
  };

  const handleTriggerSelection = (selection = null) => {
    if (!selection) return;
    setSelectedTrigger(selection);
    setSelectedResource({});
  };

  const handleResourceRequest = async (trigger, options = {}) => {
    let query_string = 'return_only=name,id,link';
    if (trigger.value === EVENT_CLICKED_LINK) {
      const [resource_type, resource_id] = options?.target.split(':');
      query_string = `${query_string}&resource_type=${resource_type}&resource_id=${resource_id}`;
    }

    if (trigger.resource_class === 'campaign') {
      query_string = `${query_string}&bool=-is_test_campaign`;
    }

    const { resources: raw_resources } = await fetchResources(trigger.resource_class, '', {
      query_string
    });
    const parsed_resources = raw_resources.map((res) => ({
      ...res,
      label: res.name || res.title || res.link,
      value: res.id
    }));

    setResources(() => parsed_resources);

    if (data) {
      const { source: existing_source } = data;
      const [resource_type, resource_id] = existing_source.split(':');
      if (resource_type !== trigger.resource_class) return;

      setSelectedResource(
        () =>
          parsed_resources.find((resource) => Number(resource.value) === Number(resource_id)) ||
          null
      );
    }
  };

  const handleResourceSelection = (selection = null) => {
    if (!selection) return;
    setSelectedResource(selection);
  };

  const handleChange = () => {
    const source = selected_resource?.value
      ? `${selected_trigger?.resource_class}:${selected_resource?.value}`
      : '';
    const data = {
      event: selected_trigger?.value,
      source
    };

    onChange(data);
  };

  return (
    <div>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Trigger">
            <MultiSelect
              options={events}
              onChange={handleTriggerSelection}
              value={selected_trigger}
              error={errors.event}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      {show_link_config && <NeuronLinkTriggerConfig onSelection={handleLinkParentSelection} />}
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Resource">
            <MultiSelect
              options={resources}
              onChange={handleResourceSelection}
              value={selected_resource}
              disabled={cannot_select_resource}
              error={errors.source}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
    </div>
  );
};
